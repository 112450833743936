import React from 'react';
import '../Contact/contact.css';
import '../Banner/index.css';
import { Link } from 'react-router-dom';

function ServicesCard({ image, title, description, alt }) {
  return (
    <div className={alt ? "contact reverse" : "contact"} style={{textAlign: 'left'}}>
      <div className="contact-image">
        <img src={image} alt={title} />
      </div>
      <div className="contact-content" style={{marginBottom: '55px'}}>,
        <h2>{title}</h2>
        <p className='contact-discription'>{description}</p>
        <Link to='/contact-us'><button class="banner-button floating-element">Book An Appointment</button></Link>
      </div>
    </div>
  );
}

export default ServicesCard;