import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.png'

function Banner() {
  return (
    <div className="banner-container">
      <h1 className="brand-name">Barn & Sons Ltd</h1>
      <Link to='/about-us'><img className="brand-icon" src={logo} alt={'brand-logo'} /></Link>
      <div className="banner-content">
        <h2>We build</h2>
        <p><i>You dream it. We build it - <b>for you.</b></i></p>
        <Link to='/contact-us'><button class="banner-button">Book An Appointment</button></Link>
        <Link to='/services'><button class="banner-button">Services We Provide</button></Link>
      </div>
    </div>
  );
}

export default Banner;