import React from 'react';
import Card from './Card'; // Import the Card component
import { whyusCards } from './data';
import './card.css'

function CardContainer({ cardsData = whyusCards }) {
    return (
        <div>
            <h2>What you can expect from us?</h2>
            <div className="card-container">
                {cardsData.map((card, index) => (
                    <Card
                        key={index}
                        image={card.image}
                        title={card.title}
                        description={card.description}
                    />
                ))}
            </div>
        </div>
    );
}

export default CardContainer;