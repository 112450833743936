import CustomerSatisfaction from '../../Assets/Customer-Satisfaction.png'
import AllServices from '../../Assets/all-services.png'
import Mission from '../../Assets/Our-Mission.png'

const service = [
    {
        image: CustomerSatisfaction,
        title: "Your Trusted Construction Partner",
        description: "Barn & Sons Ltd is a leading construction company committed to delivering exceptional building solutions. With years of experience and a team of skilled professionals, we have established a reputation for quality, reliability, and customer satisfaction."
    },
    {
        image: Mission,
        title: "Our Mission",
        description: "Our mission is to build structures that stand the test of time, while providing unparalleled customer service. We strive to exceed expectations on every project, from residential homes to commercial buildings."
    },
    {
        image: AllServices,
        title: "Our Services",
        description: "We offer a comprehensive range of construction services, including residential and commercial construction, renovation and remodeling, interior design, foundation work, framing, plumbing, electrical, and painting services. Our team of skilled professionals is dedicated to delivering exceptional quality and timely completion of every project"
    },
]

export default service;