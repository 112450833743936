// import banner from '../../Assets/banner-construction.png'
import lifecycle from '../../Assets/lifecycle.png'
import Transparency from '../../Assets/Transparency.png'
import Quality from '../../Assets/Quality.png'
import Detail from '../../Assets/Detail.png'
import CustomerSatisfaction from '../../Assets/Customer-Satisfaction.png'

const whyusCards = [
    {
        image: Transparency,
        title: 'Transparency and Communication',
        description: 'We will keep you informed throughout the entire construction process. We will provide regular updates, answer your questions promptly, and be transparent about any potential issues or delays.',
    },
    {
        image: Quality,
        title: 'Quality Workmanship',
        description: ' You should expect high-quality construction materials and skilled craftsmanship. We will adhere to building codes and industry standards to ensure a safe and durable structure.',
    },
    {
        image: lifecycle,
        title: 'Timely Completion',
        description: ' A reputable builder will have a realistic timeline for the project and will strive to meet deadlines. Delays can be frustrating and costly, so a builder who can deliver on time is a valuable asset.',
    },
    {
        image: Detail,
        title: 'Attention to Detail',
        description: ' Aa a good builder, we will pay attention to the details, from the foundation to the finishing touches. We will ensure that everything is done correctly and to your satisfaction.',
    },
    {
        image: CustomerSatisfaction,
        title: 'Customer Satisfaction',
        description: 'We are the builder who prioritizes customer satisfaction will go the extra mile to ensure you are happy with the final product. They will be responsive to your needs and willing to make adjustments as needed.',
    }
]

export { whyusCards }


