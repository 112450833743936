import React, { useState } from 'react';
import './contactForm.css'

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/api/submit-client-msg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        alert('Message sent successfully!');
        setFormData({ name: '', phone: '', email: '', message: '' });
      } else {
        alert('Error sending message. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input required type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} />
      <input required type="tel" name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} />
      <input required type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
      <textarea required name="message" placeholder="Your Message" value={formData.message} onChange={handleChange}></textarea>
      <button type="submit">Send Message</button>
    </form>
  );
}

export default ContactForm;