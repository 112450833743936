import React from 'react';
import './contact.css'; // Import the CSS file

function contact({ image, name, email, phone, addressLine1, addressLine2, addressLine3 }) {
  return (
    <div className="contact">
      <div className="contact-image">
        <img src={image} alt={name} />
      </div>
      <div className="contact-content">
        <h2>{name}</h2>
        <h4 className='contact-title'>Email us on -</h4>
        <p className='contact-discription'>{email}</p>
        <h4 className='contact-title'>Address -</h4>
        <p className='contact-discription'>{addressLine1}</p>
        <p className='contact-discription'>{addressLine2}</p>
        <p className='contact-discription'>{addressLine3}</p>
        <h4 className='contact-title'>Call us on -</h4>
        <p className='contact-discription'>{phone}</p>
        {/* <p>Address: {address}</p> */}
      </div>
    </div>
  );
}

export default contact;