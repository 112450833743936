import './App.css';
import Footer from './Components/Footer';
import RoutingComponent from './Components/RoutingComponent';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <RoutingComponent />
      </header>
      <Footer />
    </div>
  );
}

export default App;
