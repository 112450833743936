import React from 'react';
import './footer.css'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about-us">About</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/contact-us">Contact Us</Link></li>
      </ul>
      <p>&copy; 2024 Barn & Sons Ltd</p>
    </footer>
  );
}

export default Footer;