import Contact from '../../Components/Contact';
import { Helmet } from 'react-helmet';
import logo from '../../Assets/logo.png'
import FoundationServices from '../../Assets/Foundation-Services.png'
import ServicesCard from '../../Components/ServiceCard';
import service from './data';


function Services() {
    const name = 'Barn & Sons Ltd'
    const email = 'barnandsons@gmail.com'
    const phone = '+1 (519) 319-4227'
    const addressLine1 = `Care of /A I'attention de:`
    const addressLine2 = `Sara Barn, 108 Watts Drive`
    const addressLine3 = `Lucan ON N0M 2J0`
    return (
        <div className="HomePage">
            <Helmet>
                <title>Barn & Sons Ltd | Construction company</title>
            </Helmet>
            <div className="HomePage-header">
                <h1>Our Services</h1>

                {service.map((d, i) => <ServicesCard image={d.image} title={d.title} description={d.description} alt={i % 2 === 0} />)}
                <Contact image={logo} phone={phone} addressLine1={addressLine1} addressLine2={addressLine2} addressLine3={addressLine3} name={name} email={email} />
            </div>
        </div>
    );
}

export default Services;
