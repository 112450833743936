// import logo from './logo.svg';
// import './Appointment.css';
import Banner from '../../Components/Banner';
import CardContainer from '../../Components/Cards/Cards';
import Contact from '../../Components/Contact';
// import Footer from '../../Components/Footer';
import { Helmet } from 'react-helmet';
// import Banner from '../../Components/Banner';
import logo from '../../Assets/logo.png'
import ContactForm from '../../Components/ContactForm';

function Appointment() {
    const name = 'Barn & Sons Ltd'
    const email = 'barnandsons@gmail.com'
    const phone = '+1 (519) 319-4227'
    const addressLine1 = `Care of /A I'attention de:`
    const addressLine2 = `Sara Barn, 108 Watts Drive`
    const addressLine3 = `Lucan ON N0M 2J0`
    return (
        <div className="HomePage">
            <Helmet>
                <title>Barn & Sons Ltd | Construction company</title>
            </Helmet>
            <div className="HomePage-header">
                {/* <Banner />
                <CardContainer /> */}
                <h1>Book your Appointment</h1>
                <ContactForm />
                <Contact image={logo} phone={phone} addressLine1={addressLine1} addressLine2={addressLine2} addressLine3={addressLine3} name={name} email={email} />
            </div>
        </div>
    );
}

export default Appointment;
