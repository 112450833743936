import FoundationServices from '../../Assets/Foundation-Services.png'
import KitchenRemodeling from '../../Assets/kitchen-remodeling.png'
import Framing from '../../Assets/framing.png'
import Plumbing from '../../Assets/plumbing.png'
import Electrical from '../../Assets/Electrical.png'
import Painting from '../../Assets/Painting.png'

const service = [
    {
        image: FoundationServices,
        title: "Foundation Services",
        description: "Laying the groundwork for your dream project. Our team specializes in building strong and durable foundations, ensuring the stability and longevity of your structure. From slab foundations to basement foundations, we've got you covered."
    },
    {
        image: KitchenRemodeling,
        title: "Kitchen Remodeling",
        description: "Transform your kitchen into a culinary masterpiece. Our expert team can design and build custom kitchens tailored to your specific needs and style. From cabinet installation to countertop replacement, we handle every aspect of your kitchen renovation."
    },
    {
        image: Framing,
        title: "Framing Services",
        description: "The skeletal framework of your home or building. Our skilled framers construct sturdy and precise frames that provide the structural integrity of your project. From floor framing to roof framing, we ensure every detail is perfect."
    },
    {
        image: Plumbing,
        title: "Plumbing Services",
        description: "Reliable and efficient plumbing solutions. Our licensed plumbers can handle a wide range of plumbing needs, including installation, repair, and maintenance. From leaky faucets to sewer line replacements, we've got you covered."
    },
    {
        image: Electrical,
        title: "Electrical Services",
        description: "Powering your home or business. Our experienced electricians provide a comprehensive range of electrical services, including wiring, lighting installation, and panel upgrades. We ensure your electrical systems are safe, efficient, and code-compliant."
    },
    {
        image: Painting,
        title: "Painting Services",
        description: "A fresh coat of paint can transform any space. Our professional painters can apply high-quality paint finishes to both interior and exterior surfaces. From simple touch-ups to complete room transformations, we deliver exceptional results."
    }
]

export default service;
