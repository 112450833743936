import { Routes, Route } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import Appointment from "../Pages/Appointment";
import Services from "../Pages/Services";
import About from "../Pages/About";

function RoutingComponent() {

    return (
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/contact-us' element={<Appointment />} />
            <Route path='/Services' element={<Services />} />
            <Route path='/about-us' element={<About />} />
        </Routes>
    )
}

export default RoutingComponent;